import React from "react"

const initial = {
  state: { cart: [], cartMessage: ''},
  addItem: (item) => {},
  removeItem: (item) => {},
  getTotalValue: () => {},
  clearCart: () => {},
  getCartMessage: () => {},
  getItemAddedMessage: () => {},
};
const CartContext = React.createContext(initial)

class CartProvider extends React.Component {
  state = {
    cart: [],
    cartMessage: ''
  }

  componentDidMount() {
    // Get existing cart from localstorage if present.
    const existingCart = JSON.parse(
      localStorage.getItem('stripe_checkout_items')
    )
    if (existingCart && existingCart.length) {
      this.setState({ cart: existingCart })
    }
  }

  clearCart() {
    this.flashMsg('Cart cleared')
    localStorage.removeItem('stripe_checkout_items')
    
    this.setState({ cart: [] })
    // location.reload();
  }

  setQty() {

  }
  removeItem(currentItem) {

    let updatedCart = this.state.cart.filter((item) => {
      return currentItem.id !== item.id
    })
    this.setState({ cart: updatedCart })

    this.flashMsg('Item removed from cart')
    localStorage.setItem('stripe_checkout_items', JSON.stringify(updatedCart))
  }



  addItem(newItem, qty) {
    let itemExisted = false
    let updatedCart = this.state.cart.map(item => {
      if (newItem.id === item.id) {
        itemExisted = true
        return { fullItem: item.fullItem, id: newItem.id, price: newItem.priceInCents, quantity: parseInt(item.quantity) + parseInt(qty) }
      } else {
        return item
      }
    })
    if (!itemExisted) {
      updatedCart = [...updatedCart, { fullItem: newItem, id: newItem.id, quantity: qty, price: newItem.priceInCents }]
    }
    this.setState({ cart: updatedCart })
    // Store the cart in the localStorage.
    this.flashMsg('Item added to cart')
    localStorage.setItem('stripe_checkout_items', JSON.stringify(updatedCart))
  }

  getTotalValue() {
    return this.state.cart.reduce((acc, cur) => {
      acc = acc + cur.quantity * cur.price
      return acc; 
    }, 0) / 100
  }

  flashMsg(msg) {
    this.setState({ cartMessage: msg })
    const timer = setTimeout(() => {
      this.setState({ cartMessage: '' })
    }, 3000);
    return () => clearTimeout(timer);
  }

  getCartMessage() {
    return this.state.cartMessage

  }

  render() {
    const { children } = this.props
    return (
      <CartContext.Provider
        value={{
          state: this.state,
          addItem: (item, qty) => {
            this.addItem(item, qty)
          },
          removeItem: (item) => {
            this.removeItem(item)
          },
          clearCart: () => {
            this.clearCart()
          },
          getTotalValue: () => this.getTotalValue(),
          getCartMessage: () => this.getCartMessage()
        }}
      children={children}>
      </CartContext.Provider>
    )
  }
}
export default CartContext
export { CartProvider }